<template>
    <BModal v-model="showModal" id="order-modal" ref="modal" dialog-class="modal-default modal-order" :hide-footer="true">
        <template #header>
            <h5 class="modal-title" v-if="showQuoteStep">Free Quote</h5>
            <h5 class="modal-title" v-else>Order Now</h5>
            <button type="button" class="btn-close" @click="close"></button>
        </template>

        <template #default>
            <div class="how-to-order-section">
                <div class="how-to-order-step" v-show="!showQuoteStep">
                    <div class="text-block">
                        <p>
                            Orders can be placed online. Product cost and shipping charges will be calculated during the checkout process.
                            Orders ship within 24-48 hours.
                        </p>
                    </div>

                    <div class="add-to-cart-section">
                        <div :class="[{'show': showAlertSuccessMsg},'alert alert-success alert-msg alert-msg-animated']" role="alert">
                            The product has been added to your <a href="#" @click="openCheckout">shopping cart</a>.
                        </div>

                        <div :class="[{'show': showAlertDangerMsg},'alert alert-danger alert-msg alert-msg-animated']" role="alert">{{ errorText }}</div>

                        <div class="row">
                            <div class="col-md-8 col-lg-9">
                                <VFormGroup :validator="v$.product" label="Product">
                                    <BFormSelect class="products" v-model="form.product" :options="products"></BFormSelect>
                                </VFormGroup>
                            </div>
                            <div class="col-md-4 col-lg-3">
                                <VFormGroup :validator="v$.quantity" label="Quantity">
                                    <BFormInput :formatter="blockNonInteger" type="number" step="1" min="1" max="1000" class="quantity" v-model="form.quantity" id="quantity" />
                                </VFormGroup>
                            </div>
                        </div>
                    </div>

                    <div class="text-center pt-3">
                        <BButton variant="primary" @click="addProduct">Order Now</BButton>
                        <BButton variant="secondary" class="ms-3" @click="showQuoteStep = true">Free quote</BButton>
                    </div>

                    <div class="contact-section">
                        <div class="text-block text-center">
                            <p>You may also contact our experienced team and let them handle your fire rating needs.</p>

                            <h2><a href="tel:(888) 816-7468" data-allow-mismatch>(888) 816-7468</a></h2>

                            <p>
                                <a class="text-highlight-red" href="mailto:info@flameoffcoatings.com?bcc=flameoffdev@gmail.com&subject=[flameoffcoatings.com] General">info@flameoffcoatings.com</a>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="how-to-order-step" v-show="showQuoteStep">
                    <ValidationSummary :errors="errors"></ValidationSummary>

                    <VFormGroup :validator="v$.name" label="Name" hideLabel>
                        <BFormInput placeholder="Name" v-model="form.name" id="order-quote-name" />
                    </VFormGroup>
                    <VFormGroup :validator="v$.email" label="Email" hideLabel>
                        <BFormInput placeholder="Email" v-model="form.email" id="order-quote-email" />
                    </VFormGroup>
                    <VFormGroup :validator="v$.projectInformation" label="Project Info" hideLabel>
                        <BFormTextarea v-model="form.projectInformation"
                                       placeholder="Project information"
                                       class="vresize"
                                       id="order-projection-information"
                                       rows="5"></BFormTextarea>
                    </VFormGroup>
                    <VFormGroup :validator="v$.zipCode" label="Zip Code" hideLabel>
                        <BFormInput placeholder="ZIP code for delivery" v-model="form.zipCode" id="order-quote-zipcode" />
                    </VFormGroup>

                    <div class="mb-3">
                        <Dropzone id="order-quote-dropzone"
                                  action="/api/order/fileUploadCheckout"
                                  :maxFiles="10"
                                  acceptedFiles="image/*,.pdf"
                                  :isOrderNowDropzone="true"
                                  @fileUploaded="fileUploaded"
                                  @fileRemoved="fileRemoved"></Dropzone>
                    </div>

                    <div class="mb-3">
                        <BFormCheckbox v-model="subscribe" id="order-quote-subscribe">Sign up for our newsletter</BFormCheckbox>
                    </div>

                    <div class="form-text">This site is protected by reCAPTCHA</div>
                    <div class="form-text">
                        <a href="https://policies.google.com/privacy" class="link-dark" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="https://policies.google.com/terms" class="link-dark" target="_blank" rel="noopener noreferrer">Terms of Service</a> apply
                    </div>

                    <div class="mt-3 d-flex justify-content-end">
                        <BtnLoading :loading="loading" @click="submit" id="free-quote-send">
                            Send
                        </BtnLoading>
                    </div>
                </div>
            </div>
        </template>
    </BModal>
</template>

<script lang="ts" setup>
    import type { SelectListOption } from '@/types/models/select-list-item';
    import { requiredIf, minValue, maxValue, email } from '@vuelidate/validators';
    import { cartService, messageService, productService } from '@/services';

    const emit = defineEmits(['productAdded']);
    const { renderRecaptchaScript, destroyRecaptchaScript, executeRecaptcha, token } = useRecaptcha();
    const { attachments, fileRemoved, fileUploaded } = useDropzone();

    const products = ref<SelectListOption[]>([]);
    const showQuoteStep = ref(false);
    const subscribe = ref(true);
    const customerId = ref(0);
    const errorText = ref('');

    const form = ref({
        quantity: 1,
        product: 0,
        name: '',
        email: '',
        projectInformation: '',
        zipCode: ''
    });

    const validations = {
        quantity: { requiredIf: requiredIf(!showQuoteStep), minValue: minValue(1), maxValue: maxValue(1000) },
        product: { requiredIf: requiredIf(!showQuoteStep) },
        name: { requiredIf: requiredIf(showQuoteStep) },
        email: { requiredIf: requiredIf(showQuoteStep), email },
        projectInformation: { requiredIf: requiredIf(showQuoteStep) },
        zipCode: { requiredIf: requiredIf(showQuoteStep) }
    };

    const showAlertSuccessMsg = ref(false);
    const showAlertDangerMsg = ref(false);

    const route = useRoute();
    const router = useRouter();
    const { successToast } = useToast();

    const isCheckoutPage = computed(() => {
        return route.path.includes('/checkout') || route.path.includes('/cart');
    });

    async function beforeShow(custId: number) {
        showQuoteStep.value = false;

        products.value = await productService.getAllProductVariants();

        form.value.product = parseInt(products.value[0].value);

        customerId.value = custId;

        renderRecaptchaScript();
    }

    async function onSubmit() {
        await executeRecaptcha();

        const res = await messageService.verifyRecaptcha(token.value);

        if (!res.success)
            return;

        await messageService.freeQuateSend({
            attachmentUrls: attachments.value.map(u => u.url),
            email: form.value.email,
            name: form.value.name,
            projectInfo: form.value.projectInformation,
            zipCode: form.value.zipCode
        });

        if (subscribe.value)
            await messageService.subscribeNewsletter({ email: form.value.email });

        successToast("Sent successfully!");
    }

    async function addProduct() {
        if (!showQuoteStep.value) {
            if (form.value.quantity < 1 || form.value.quantity > 1000) {
                showError(form.value.quantity < 1 ? 'Quantity should be more than 0' : 'Quantity should be less than 1000');

                return;
            }

            await cartService.addProductToCart({
                productVariantId: form.value.product,
                quantity: form.value.quantity,
                customerId: customerId.value
            });

            if (isCheckoutPage.value)
                window.location.reload();
            else
                showSuccess();
        }
    }

    function showSuccess() {
        showAlertSuccessMsg.value = true;

        setTimeout(() => {
            showAlertSuccessMsg.value = false;
        }, 1000 * 5);

        emit('productAdded');
    }

    function showError(errorMessage: string) {
        errorText.value = errorMessage;

        showAlertDangerMsg.value = true;

        setTimeout(() => {
            showAlertDangerMsg.value = false;
        }, 1000 * 5);
    }

    function openCheckout() {
        router.push('/cart');

        showModal.value = false;
    }

    function close() {
        showModal.value = false;
    }

    const { submit, errors, showModal, show, v$, loading } = useFormModal(validations, form, onSubmit, beforeShow);

    watch(showModal, (newVal) => {
        if (!newVal)
            destroyRecaptchaScript();
    });

    defineExpose({
        show
    });
</script>

<style lang="scss">
    @import '@/assets/styles/_modals';
    @import '@/assets/styles/_forms';
    @import '@/assets/styles/_modal-order';

    .alert-msg-animated {
        display: none;
        transition: max-height 0.5s ease, opacity 0.5s ease;
        overflow: hidden;
        max-height: 0;
        opacity: 0;

        &.show {
            display: block;
            max-height: 100px;
            opacity: 1;
        }
    }
</style>