<template>
    <BModal v-model="showModal" id="cookie-modal" ref="modal" dialog-class="modal-default modal-order" header-class="d-block" centered>
        <template #header>
            <h5 class="modal-title">Privacy Overview</h5>
        </template>

        <template #default>
            <main class="cookie-comply-modal-content">
                <div v-for="(preference, index) in preferences" :key="`Cookie_${index}`" class="row">
                    <div class="col-10">
                        <h4>{{ preference.title }}</h4>
                        <small>{{ preference.description }}</small>
                    </div>
                    <div v-for="(item, index) in preference.items" :key="item.value" class="col-2 cookie-comply-modal-switches">
                        <BFormCheckbox :id="item.value"
                                       v-model="item.isEnabled"
                                       :disabled="item.isRequired"
                                       switch />
                    </div>
                    <hr class="mt-3" v-if="preferences && index !== preferences.length - 1" />
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <a href="/privacy-policy" class="text-decoration-underline">
                            Read our Privacy Policy
                        </a> 
                    </div>
                </div>
            </main>
        </template>

        <template #footer>
            <BButton variant="secondary" class="me-3" @click="close">Cancel</BButton>
            <BButton variant="primary" @click="saveConfiguration">Save & Accept</BButton>
        </template>
    </BModal>
</template>

<script lang="ts" setup>
    import type { CookieCategory } from "@/types/models/cookies/cookie-category";

    const emit = defineEmits(['cookie-comply-save']);

    const preferences = ref<CookieCategory[]>([]);

    const showModal = ref(false);

    function show(items: CookieCategory[]) {
        showModal.value = true;
        preferences.value = items;
    }

    function close() {
        showModal.value = false;
    }

    function saveConfiguration() {
        const enabledItemsCount = preferences.value.reduce((count, category) => count + category.items.filter(item => item.isEnabled).length, 0);
        const totalItemsCount = preferences.value.reduce((count, category) => count + category.items.length, 0);

        emit('cookie-comply-save', totalItemsCount === enabledItemsCount);
    }

    defineExpose({
        show
    });
</script>

<style lang="scss" scoped>
    .modal-default {
        .modal-title {
            text-align: center;
            border-bottom: 1px solid rgba(200, 200, 200, 0.2);
            padding-bottom: 1rem;
        }

        .cookie-comply-modal-content {
            .cookie-comply-modal-switches {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            hr {
                border-top: 1px solid rgba(200, 200, 200) !important;
            }
        }
    }
</style>