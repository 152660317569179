import type { CookieCategory } from "@/types/models/cookies/cookie-category";

export const cookieCategories: CookieCategory[] = [{
    title: 'Necessary Cookies',
    description: `Necessary cookies encapsulate cookies required to maintain site functionality and
                  cannot be customized or turned off.Choosing to continue browsing our website means
                  accepting the use of necessary cookies.`,
    items: [
        {
            isRequired: true,
            isEnabled: true,
            title: 'flameoff'
        }
    ]
},
{
    title: 'Other Categories of Cookies',
    description: `Other cookies are used on this site. These cookies are related to improving user
                  experience and functionality and personalizing content, which involves tracking some
                  user information.`,
    items: [
        {
            isRequired: false,
            isEnabled: false,
            title: 'ga'
        }
    ]
}];