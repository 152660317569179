<template>
    <aside v-if="showCookieComply" class="cookie-comply">
        <div class="cookie-comply-header">
            <h5 class="fw-bold">Cookies Settings</h5>
            <p class="cookie-comply-header-description">
                We use cookies and similar technologies to help personalize content and offer a better
                experience. You can opt to customize them by clicking the preferences button.
            </p>
        </div>

        <div class="cookie-comply-actions">
            <BButton variant="secondary" @click="openModal">
                Preferences
            </BButton>
            <BButton variant="primary" class="cookie-comply-button-accept" @click="handleAcceptAll">
                Accept All
            </BButton>
        </div>

        <CookieComplyModal ref="cookieModal" @cookie-comply-save="onSave" />
    </aside>
</template>

<script lang="ts" setup>
    import type { BootstrapModalRef } from '@/types/models/default-refs-types';

    import { cookieCategories } from '@/constants/cookie-categories';
    import { customerService } from '@/services';

    const cookieModal = ref<BootstrapModalRef>();

    const { userId } = useUserSession();

    const showCookieComply = ref(false);

    onMounted(async () => {
        await setCookieComply()
    });

    async function setCookieComply() {
        const cookie = useCookie('cookie-comply', { maxAge: 7948800 });

        if (cookie.value !== undefined) {
            if (cookie.value)
                initUTMTags();

            return;
        }

        const isEnabledAnalytics = await customerService.getCustomerCookieComplay(userId.value);

        if (isEnabledAnalytics !== null) {
            cookie.value = isEnabledAnalytics!.toString();

            if (isEnabledAnalytics)
                initUTMTags();

            location.reload();

            return;
        }

        initUTMTags();

        showCookieComply.value = true;
    };

    async function handleAcceptAll() {
        await onSave(true);
    }

    async function onSave(data: boolean) {
        const cookie = useCookie('cookie-comply', { maxAge: 7948800 });

        cookie.value = data.toString();

        if (!data)
            forceCleanUTMTags();

        await customerService.setCustomerCookieComplay(data, userId.value);

        location.reload();
    }

    function openModal() {
        cookieModal.value!.show(cookieCategories);
    }

    function initUTMTags() {
        setCookie('utm-source-cookie', 'utm_source', true);
        setCookie('utm-campaign-cookie', 'utm_campaign');
        setCookie('utm-keyword-cookie', 'utm_term');
    }

    function setCookie(cookieName: string, utmKey: string, isSource = false) {
        const utm = new URL(window.location.href).searchParams.get(utmKey);
        const cookie = useCookie(cookieName, { maxAge: 7948800 });

        if (utm)
            cookie.value = utm;
        else if (isSource)
            if (document.referrer !== "" && !document.referrer.includes(location.origin))
                cookie.value = document.referrer;
    }

    function forceCleanUTMTags() {
        useCookie('utm-source-cookie').value = undefined;
        useCookie('utm-campaign-cookie').value = undefined;
        useCookie('utm-keyword-cookie').value = undefined;
    }

    const route = useRoute();

    watch([() => route.path, userId], () => {
        setCookieComply();
    });
</script>

<style lang="scss" scoped>
    .cookie-comply {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr minmax(35%, 40%);
        position: absolute;
        bottom: 8px;
        left: 10%;
        right: 10%;
        background-color: #fff;
        box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.1), 0 1px 7px 1px rgba(0, 0, 0, 0.06);
        padding: 14px;
        border-radius: 8px;
        z-index: 10;
        position: fixed;

        @media (max-width: 1024px) {
            grid-template-columns: none;
        }

        .cookie-comply-header-description {
            margin-bottom: 0px;
        }

        .cookie-comply-actions {
            display: grid;
            grid-gap: 20px;
            grid-template-columns: repeat(2, 1fr);
            align-self: center;

            @media (max-width: 480px) {
                grid-template-columns: auto;
            }
        }
    }
</style>