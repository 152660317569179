<template>
    <div>
        <StoreHeader critical />

        <div class="body-content">
            <slot />
        </div>

        <StoreFooter />

        <CookieComply />
    </div>
</template>

<script lang="ts" setup>
    import metaTags from "@/constants/seo-constants";

    const route = useRoute();

    watch(() => route.path, () => {
        setMetaObject();
    });

    function setMetaObject() {
        const config = useRuntimeConfig();
        const metaObject = metaTags.get(route.path) ?? metaTags.get('/');

        useHead({
            title: metaObject?.title,
            meta: [
                { name: 'description', content: metaObject?.desc }
            ],
            link: [
                { rel: "canonical", href: config.public.baseURL + route.path }
            ]
        });
    }

    setMetaObject();
</script>

<style lang="scss">
    @import '@/assets/styles/app.scss';
    @import 'vue-toastification/dist/index.css';
    @import '@/assets/fonts/google-fonts.scss';
</style>