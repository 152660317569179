<template>
    <BModal v-model="showModal" id="quote-modal" ref="modal" dialog-class="modal-default modal-order" :hide-footer="true">
        <template #header>
            <h5 class="modal-title">Free Quote</h5>
            <button type="button" class="btn-close" @click="close"></button>
        </template>

        <template #default>
            <div class="how-to-order-section">
                <ValidationSummary :errors="errors"></ValidationSummary>

                <div class="how-to-order-step">
                    <VFormGroup :validator="v$.name" label="Name" hideLabel>
                        <BFormInput placeholder="Name" v-model="form.name" id="quote-name" />
                    </VFormGroup>
                    <VFormGroup :validator="v$.email" label="Email" hideLabel>
                        <BFormInput placeholder="Email" v-model="form.email" id="quote-email" />
                    </VFormGroup>
                    <VFormGroup :validator="v$.projectInformation" label="Project Info" hideLabel>
                        <BFormTextarea v-model="form.projectInformation"
                                       placeholder="Project information"
                                       class="vresize"
                                       id="projection-information"
                                       rows="5"></BFormTextarea>
                    </VFormGroup>
                    <VFormGroup :validator="v$.zipCode" label="Zip Code" hideLabel>
                        <BFormInput placeholder="ZIP code for delivery" v-model="form.zipCode" id="quote-zipcode" />
                    </VFormGroup>

                    <div class="mb-3">
                        <Dropzone id="quote-dropzone"
                                  action="/api/order/fileUploadCheckout"
                                  :maxFiles="10"
                                  acceptedFiles="image/*,.pdf"
                                  :isOrderNowDropzone="true"
                                  @fileUploaded="fileUploaded"
                                  @fileRemoved="fileRemoved"></Dropzone>
                    </div>

                    <div class="mb-3">
                        <BFormCheckbox v-model="subscribe" id="quote-subscribe">Sign up for our newsletter</BFormCheckbox>
                    </div>

                    <div class="form-text">This site is protected by reCAPTCHA</div>
                    <div class="form-text">
                        <a href="https://policies.google.com/privacy" class="link-dark" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="https://policies.google.com/terms" class="link-dark" target="_blank" rel="noopener noreferrer">Terms of Service</a> apply
                    </div>

                    <div class="mt-3 d-flex justify-content-end">
                        <BtnLoading @click="submit" :loading="loading" id="free-quote-send">
                            Send
                        </BtnLoading>
                    </div>
                </div>
            </div>
        </template>
    </BModal>
</template>

<script lang="ts" setup>
    import { required, minValue, maxValue, email } from '@vuelidate/validators';
    import { messageService } from '@/services';

    const { renderRecaptchaScript, destroyRecaptchaScript, executeRecaptcha, token } = useRecaptcha();
    const { attachments, fileRemoved, fileUploaded } = useDropzone();

    const subscribe = ref(true);

    const form = ref({
        quantity: 1,
        name: '',
        email: '',
        projectInformation: '',
        zipCode: ''
    });

    const validations = {
        quantity: { required, minValue: minValue(1), maxValue: maxValue(1000) },
        name: { required },
        email: { required, email },
        projectInformation: { required },
        zipCode: { required }
    };

    const { successToast } = useToast();

    async function beforeShow() {
        renderRecaptchaScript();
    }

    async function onSubmit() {
        await executeRecaptcha()

        const res = await messageService.verifyRecaptcha(token.value);

        if (!res.success)
            return;

        await messageService.freeQuateSend({
            attachmentUrls: attachments.value.map(u => u.url),
            email: form.value.email,
            name: form.value.name,
            projectInfo: form.value.projectInformation,
            zipCode: form.value.zipCode
        });

        if (subscribe.value)
            await messageService.subscribeNewsletter({ email: form.value.email });

        successToast("Sent successfully!");
    }

    function close() {
        showModal.value = false;
    }

    const { submit, errors, showModal, show, v$, loading } = useFormModal(validations, form, onSubmit, beforeShow);

    watch(showModal, (newVal) => {
        if (!newVal)
            destroyRecaptchaScript();
    });

    defineExpose({
        show
    });
</script>

<style lang="scss">
    @import '@/assets/styles/_modal-order';
</style>